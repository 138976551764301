import { IoLogoElectron } from 'react-icons/io5';
import './Upload.page.css';
import { useState } from 'react';

interface State {
  url: string;
  title: string;
  transcript: string;
  file: File | null;
  audio:  File | null;
}

function UploadPage(): JSX.Element {
  const [activeForm, setActiveForm] = useState<string>('youtube');
  const [formData, setFormData] = useState<State>({
    url: '',
    title: '',
    file: null,
    transcript: '',
    audio: null,
  });

  const handleButtonClick = (formName: string) => {
    setActiveForm(formName);
    setFormData({ url: '', title: '', file: null, transcript: '', audio: null });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const content = e.target?.result as string;
  //       setFormData({ ...formData, transcript: content });
  //     };
  //     reader.readAsText(file);
  //   }
  // };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      
      // Check which form is active
      if (activeForm === 'audio') {
        // Handle audio file upload
        if (file.type.startsWith('audio')) {
          reader.onload = (e) => {
            const content = e.target?.result as string;
            setFormData({ ...formData, audio: file }); // Store base64 audio data
          };
          reader.readAsDataURL(file); // Converts file to base64
        } else {
          console.error("Invalid file type. Please upload an audio file.");
        }
      } else if (activeForm === 'transcript') {
        // Handle transcript file upload
        if (file.type === 'text/plain') {
          reader.onload = (e) => {
            const content = e.target?.result as string;
            setFormData({ ...formData, transcript: content }); // Store transcript text
          };
          reader.readAsText(file); // Reads file as plain text
        } else {
          console.error("Invalid file type. Please upload a .txt file.");
        }
      }
    }
  };
  

  // Function to handle YouTube URL upload
  const handleYouTubeUpload = async () => {
    const jsonData = {
      url: formData.url,
      requestedBy: 'Admin',
    };

    await uploadData('/upload/youtubeurl', jsonData);
  };

  // Function to handle audio upload
  // const handleAudioUpload = async () => {
  //   const jsonData = {
  //     title: formData.title,
  //     url: formData.url,
  //     audio:formData.audio,
  //     requestedBy: 'Admin',
  //     embedLink: null,
  //   };

  //   await uploadData('/upload/audio', jsonData);
  // };


  // Function to handle audio upload
const handleAudioUpload = async () => {
  if (!formData.audio) {
    console.error("Audio file is required.");
    return;
  }

  // Convert audio file to base64
  const reader = new FileReader();
  reader.onloadend = async () => {
    const base64Audio = reader.result?.toString().split(',')[1]; // Get base64 string without metadata

    const jsonData = {
      title: formData.title,
      url: formData.url,
      audio: base64Audio, // Include base64 audio data
      requestedBy: 'Admin',
      embedLink: null,
    };

    await uploadData('/upload/audio', jsonData);
  };

  reader.readAsDataURL(formData.audio); // Convert file to base64
};

  // Function to handle transcript upload
  const handleTranscriptUpload = async () => {
    const jsonData = {
      title: formData.title,
      url: formData.url,
      transcript: formData.transcript,
      requestedBy: 'Admin',
      embedLink: null,
    };

    await uploadData('/upload/transcript', jsonData);
  };

  // Unified function to submit data
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (activeForm === 'youtube') {
      await handleYouTubeUpload();
    } else if (activeForm === 'audio') {
      await handleAudioUpload();
    } else if (activeForm === 'transcript') {
      await handleTranscriptUpload();
    }
  };

  // Helper function to upload data
  const uploadData = async (endpoint: string, jsonData: any) => {
    try {
      console.log(formData);
      console.log(activeForm);
      const uploadResponse = await fetch(`${endpoint}`, {
        method: 'POST',
        body: JSON.stringify(jsonData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!uploadResponse.ok) {
        const errorResponse = await uploadResponse.text();
        console.error('Upload failed:', errorResponse);
        throw new Error(`Network response was not ok: ${uploadResponse.statusText}`);
      }

      console.log('Upload successful');
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  return (
    <div>
      <div className="logoName adminLogoName">
        <IoLogoElectron className="logo" />
        <h3>Video Search AI</h3>
      </div>
      <div className='upload-page'>
        <h1 className='upload-head'>Upload</h1>
        <div className='buttons-bar'>
          <button
            className={`upload-btns ${activeForm === 'youtube' ? 'active' : ''}`}
            onClick={() => handleButtonClick('youtube')}
          >
            YouTube URL
          </button>
          <button
            className={`upload-btns ${activeForm === 'audio' ? 'active' : ''}`}
            onClick={() => handleButtonClick('audio')}
          >
            Audio
          </button>
          <button
            className={`upload-btns ${activeForm === 'transcript' ? 'active' : ''}`}
            onClick={() => handleButtonClick('transcript')}
          >
            Transcript
          </button>
        </div>
        <div className='upload-forms'>
          {activeForm === 'youtube' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleChange}
                className="upload-input"
                placeholder="Enter YouTube URL..."
                required
              />
              <button type="submit" className="submit-form">Submit</button>
            </form>
          )}

          {activeForm === 'audio' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="upload-input"
                placeholder="Enter Title..."
                required
              />
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleChange}
                className="upload-input"
                placeholder="Enter URL..."
                required
              />
              <input
                type="file"
                name="audio"
                onChange={handleFileChange}
                className="upload-input"
                placeholder="Upload audio file..."
                accept="audio/*"
                required
              />
              <button type="submit" className="submit-form">Submit</button>
            </form>
          )}

          {activeForm === 'transcript' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleChange}
                className="upload-input"
                placeholder="Enter URL..."
                required
              />
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="upload-input"
                placeholder="Enter Title..."
                required
              />
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                className="upload-input"
                accept=".txt"
                placeholder="Upload transcript..."
                required
              />
              <button type="submit" className="submit-form">Submit</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
