import { useState } from 'react';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import TranscriptTable from '../../ui/panels/TranscriptTable';
import StudyTable from '../../ui/panels/StudyTable';
import StudyForm from '../../ui/panels/StudyForm';
import Signout from '../User/signout';
import { IoLogoElectron } from 'react-icons/io5';
import './Admin.page.css';

const PAGE_SIZE = 20;
const isMock = process.env.REACT_APP_MOCK === 'true';

// This is a partial transcript because I'm unsure if we should allow changing more than this / knowing more than this on the front end
interface PartialTranscript {
  transcriptId: number;
  url: string;
  title: string;
}

interface StudyModel {
  studyId: number;
  transcriptId: number;
  studyUrl: string;
  studyTitle: string;
  studyAbstract: string | null;
  studyKeywords: string[] | null;
  dateAdded: string;
  dateUpdated: string | null;
  addedBy: string;
  updatedBy: string | null;
}

interface PanelData {
  transcript: PartialTranscript;
  studies: StudyModel[];
}

function AdminPage(): JSX.Element {
  const USER = 'admin'; // When we have a proper use system we can put the username in here (assuming existence of admin users)
  // In place of a actual user system which we would probably get done in the near future
  const [pageId, setPageId] = useState<null | number>(null);
  const [primaryData, setPrimaryData] = useState<null | PanelData[]>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataError, setLoadingDataError] = useState(false);

  // The list of transcripts to show on the transcript table
  const [transcripts, setTranscripts] = useState<PartialTranscript[]>([]);

  // Specific transcript and its associated studies / info
  const [selectedData, setSelectedData] = useState<null | PanelData>(null);
  // Study which we are looking at
  const [selectedStudy, setSelectedStudy] = useState<StudyModel | null>(null);

  // Not sure if we should allow editing transcripts?
  const [editingTranscript, setEditingTranscript] = useState(false);

  const [editingStudy, setEditingStudy] = useState(false);
  const [creatingStudy, setCreatingStudy] = useState(false);

  // used to hold the data of the newly created study or the study which we are editing
  const [temporaryStudy, setTemporaryStudy] = useState<StudyModel | null>(null);
  const [sendingStudyUpdate, setSendingStudyUpdate] = useState(false);
  const [submittingNewStudy, setSubmittingNewStudy] = useState(false);

  // Errors are tracked, however how you want to report them visualy / remove the error flags I'm unsure
  const [studyUpdateError, setStudyUpdateError] = useState(false);
  const [studyDeleteError, setStudyDeleteError] = useState(false);
  const [submittingNewStudyError, setSubmittingNewStudyError] = useState(false);

  const handleStudyTableBackPressed = () => {
    setSelectedData(null);
    setSelectedStudy(null);
  };

  const handleStudyTableAddPressed = () => {
    setCreatingStudy(true);
    setTemporaryStudy({
      studyId: -1,
      transcriptId: selectedData?.transcript.transcriptId,
      studyUrl: '',
      studyTitle: '',
      studyAbstract: '',
      studyKeywords: [],
      dateAdded: '',
      dateUpdated: '',
      addedBy: USER,
      updatedBy: '',
    } as StudyModel);
  };

  const handleEditTranscriptPressed = (transcriptId: number) => {
    if (primaryData == null) {
      return;
    }
    for (const item of primaryData) {
      if (item.transcript.transcriptId === transcriptId) {
        setSelectedData(item);
        break;
      }
    }
  };

  const handleStudyOnEditPressed = (study: StudyModel) => {
    setSelectedStudy(study);
    if (study.studyKeywords == null) {
      setTemporaryStudy({
        ...study,
        studyKeywords: [],
      } as StudyModel);
    } else {
      setTemporaryStudy(study);
    }
    setEditingStudy(true);
  };

  const handleStudySubmitPressed = () => {
    if (editingStudy) {
      updateStudy();
    } else if (creatingStudy) {
      submitNewStudy();
    } else {
      console.log(
        'There is an error, either your editing or creating a study and somehow your neither?',
      );
    }
  };

  const handleDeleteStudyPressed = async (study: StudyModel) => {
    if (selectedData == null) return;
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/delete/studies${mockEndpoint}`;
    const jsonData = {
      transcriptId: selectedData?.transcript.transcriptId,
      studyId: study.studyId,
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const newStudies: StudyModel[] = [];
      for (const item of selectedData.studies) {
        if (item.studyId != study.studyId) {
          newStudies.push(item);
        }
      }
      const newPrimaryData: PanelData[] = [];
      let newSelectedItem: PanelData = selectedData;
      if (primaryData != null) {
        for (const item of primaryData) {
          if (item.transcript.transcriptId == selectedData.transcript.transcriptId) {
            const temp = { ...item, studies: newStudies };
            newSelectedItem = temp;
            newPrimaryData.push(temp);
          } else {
            newPrimaryData.push(item);
          }
        }
      }
      setSelectedData(newSelectedItem);
      setPrimaryData(newPrimaryData);
    } catch (e) {
      console.log(`An error ocurred when trying to delete:${study}`);
      setStudyDeleteError(true);
    }
  };

  const handleStudyFormBackPressed = () => {
    if (creatingStudy && !editingStudy) {
      setCreatingStudy(false);
      setTemporaryStudy(null);
    } else if (!creatingStudy && editingStudy) {
      setSelectedStudy(null);
      setTemporaryStudy(null);
      setEditingStudy(false);
    }
  };

  const submitNewStudy = async () => {
    setSubmittingNewStudy(true);
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/upload/studies${mockEndpoint}`;

    const jsonData = {
      transcriptId: selectedData?.transcript.transcriptId,
      studyUrl: temporaryStudy?.studyUrl,
      studyTitle: temporaryStudy?.studyTitle,
      studyAbstract: temporaryStudy?.studyAbstract,
      studyKeywords: temporaryStudy?.studyKeywords,
      addedBy: USER,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const result: StudyModel = await response.json();
      const studies = selectedData?.studies;
      studies?.push(result);
      const newPrimaryData: PanelData[] = [];
      if (primaryData != null && Array.isArray(primaryData) && studies != null) {
        for (const item of primaryData) {
          const itemData = item as PanelData;
          if (itemData.transcript.transcriptId == selectedData?.transcript.transcriptId) {
            newPrimaryData.push({ ...itemData, studies: studies });
          } else {
            newPrimaryData.push(itemData);
          }
        }
      }

      setPrimaryData(newPrimaryData);
      setSelectedStudy(null);
      setTemporaryStudy(null);
      setSubmittingNewStudy(false);
    } catch (error) {
      console.error('There was a problem with uploading the study:', error);
      if (error instanceof TypeError) {
        console.error('TypeError details:', error.message);
      }
      setSubmittingNewStudyError(true);
    }
  };

  const updateStudy = async () => {
    if (selectedData == null) return;
    setSendingStudyUpdate(true);
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/update/studies${mockEndpoint}`;
    const jsonData = {
      transcriptId: selectedData?.transcript.transcriptId,
      studyId: selectedStudy?.studyId,
      studyUrl: temporaryStudy?.studyUrl,
      studyTitle: temporaryStudy?.studyTitle,
      studyAbstract: temporaryStudy?.studyAbstract,
      studyKeywords: temporaryStudy?.studyKeywords,
      addedBy: selectedStudy?.addedBy,
      dateAdded: selectedStudy?.dateAdded,
      updatedBy: USER,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const studies: StudyModel[] = [];
      if (selectedData != null) {
        for (const item of selectedData.studies) {
          if (item == selectedStudy && temporaryStudy != null) {
            studies.push(temporaryStudy);
          } else {
            studies.push(item);
          }
        }
      }
      const newPrimaryData: PanelData[] = [];
      let newSelectedData: PanelData = selectedData;
      if (primaryData != null && Array.isArray(primaryData) && studies != null) {
        for (const item of primaryData) {
          const itemData = item as PanelData;
          if (itemData.transcript.transcriptId == selectedData?.transcript.transcriptId) {
            const temp = { ...itemData, studies: studies } as PanelData;
            newPrimaryData.push(temp);
            newSelectedData = temp;
          } else {
            newPrimaryData.push(itemData);
          }
        }
      }
      setPrimaryData(newPrimaryData);
      setSelectedData(newSelectedData);
      setTemporaryStudy(null);
      setSelectedStudy(null);
      setEditingStudy(false);
    } catch (error) {
      console.error('There was a problem with uploading the study:', error);
      if (error instanceof TypeError) {
        console.error('TypeError details:', error.message);
      }
      setStudyUpdateError(true);
    }
  };

  const requestTranscripts = async () => {
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/transcripts${mockEndpoint}?pageSize=${PAGE_SIZE}`;

    if (pageId !== null) {
      url += `&transcriptId=${pageId}`;
    }
    setLoadingData(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(`An issue occured retrieving transcripts`);
        }
        return response.json();
      })
      .then((response) => {
        const result: PanelData[] = response.map((item: PanelData) => ({
          transcript: item.transcript as PartialTranscript, // Spread the properties of the transcript object
          studies: item.studies, // Add the studies array
        }));

        setLoadingData(false);
        setPrimaryData(result);
        const transcriptArray: PartialTranscript[] = [];
        for (const item of result) {
          transcriptArray.push(item.transcript);
        }
        setTranscripts(transcriptArray);
      })
      .catch((err) => {
        console.log(`In error`);
        if (err) {
          setLoadingData(false);
          setLoadingDataError(true);
        }
      });
  };

  const handleEditedStudyChangeTitle = (newName: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyTitle: newName, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyChangeUrl = (newUrl: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyUrl: newUrl,
    } as StudyModel);
  };

  const handleEditedStudyChangeAbstract = (newAbstract: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyAbstract: newAbstract, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyAddKeyword = (newKeyword: string) => {
    const keywords = temporaryStudy?.studyKeywords;
    keywords?.push(newKeyword);
    setTemporaryStudy({
      ...temporaryStudy,
      studyKeywords: keywords, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyRemoveKeyword = (removeKeyword: string) => {
    const keywords: string[] = [];
    if (temporaryStudy?.studyKeywords != null) {
      for (const item of temporaryStudy?.studyKeywords) {
        if (item !== removeKeyword) {
          keywords.push(item);
        }
      }
    }
    setTemporaryStudy({
      ...temporaryStudy,
      studyKeywords: keywords, // Update only the studyTitle property
    } as StudyModel);
  };

  if (!loadingData && primaryData == null && !loadingDataError) {
    requestTranscripts();
  }

  if (loadingData) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  // if (!loadingData && primaryData?.length === 0) {
  //   // maybe just show an empty table
  //   return <p>No transcripts were found, critical error</p>;
  // }
  if (primaryData != null) {
    return (
      <div>
        {selectedData == null && (
          <div>
            <div className="headerWrapper">
              <div className="logoName adminLogoName">
                <IoLogoElectron className="logo" />
                <h3>Video Search AI</h3>
              </div>
              <div> <Signout/></div>
            </div>
            {/* <p>Transcripts</p> */}
            <TranscriptTable
              data={transcripts}
              onEdit={handleEditTranscriptPressed}
              onDelete={function (transcriptId: number): void {
                console.log(`Need to discuss if we want transcripts deletable here`);
              }}
            ></TranscriptTable>
          </div>
        )}
        {selectedData != null && selectedStudy == null && temporaryStudy == null && (
          <div className="editPage">
            <button className="backButton" onClick={handleStudyTableBackPressed}>
              Back
            </button>
            <p className="transcriptTitle">Title: {selectedData.transcript.title}</p>
            <a href={selectedData.transcript.url}>
              <p className="transcriptURL">URL: {selectedData.transcript.url}</p>
            </a>
            <StudyTable
              data={selectedData.studies}
              onEdit={handleStudyOnEditPressed}
              onDelete={handleDeleteStudyPressed}
            ></StudyTable>
            <button className="addStudyButton" onClick={handleStudyTableAddPressed}>
              Add Study
            </button>
          </div>
        )}
        {temporaryStudy != null &&
          temporaryStudy.studyTitle != null &&
          temporaryStudy.studyUrl != null &&
          temporaryStudy.studyAbstract != null &&
          temporaryStudy.studyKeywords != null && (
            <div>
              {creatingStudy ? '' : 'Editing Study'}
              <StudyForm
                onSubmit={handleStudySubmitPressed}
                onBack={handleStudyFormBackPressed}
                onNameChange={handleEditedStudyChangeTitle}
                onAbstractChange={handleEditedStudyChangeAbstract}
                onUrlChange={handleEditedStudyChangeUrl}
                onKeywordAdded={handleEditedStudyAddKeyword}
                onKeywordRemoved={handleEditedStudyRemoveKeyword}
                isLoading={sendingStudyUpdate || submittingNewStudy}
                studyName={temporaryStudy.studyTitle}
                studyUrl={temporaryStudy.studyUrl}
                studyAbstract={temporaryStudy.studyAbstract}
                studyKeywords={temporaryStudy.studyKeywords}
              ></StudyForm>
            </div>
          )}
      </div>
    );
  }

  return <p>If your seeing this something bad happened, maybe the server is down?</p>;
}

export default AdminPage;
