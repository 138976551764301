import { useNavigate } from 'react-router-dom';
import "./signout.css"

const Signout = () => {
  const navigate = useNavigate();

  const handleSignout = () => {
    localStorage.removeItem('token');  // Remove token from localStorage
    navigate('/signin');  // Redirect to signin page
  };

  return <button onClick={handleSignout}>Sign Out</button>;
};

export default Signout;
