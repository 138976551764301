import React from 'react';
import './TranscriptTable.css';
import { Link } from 'react-router-dom';

interface PartialTranscript {
  transcriptId: number;
  url: string;
  title: string;
}

interface Props {
  data: PartialTranscript[];
  onEdit: (transcriptId: number) => void;
  onDelete: (transcriptId: number) => void;
}

function TranscriptTable({ data, onEdit, onDelete }: Props): JSX.Element {
  console.log(`showing transcript table`);
  return (
    <>
    <Link to='/admin/upload'><button className="upload-btn">Upload</button></Link>
    <table>
      <thead>
        <tr>
          <th>Transcript ID</th>
          <th>Title</th>
          <th>URL</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.transcriptId}>
            <td>{item.transcriptId}</td>
            <td>{item.title}</td>
            <td>{item.url}</td>
            <td>
              <div className="editButtons">
                <button className="edit" onClick={() => onEdit(item.transcriptId)}>
                  Edit
                </button>
                <button className="delete" onClick={() => onDelete(item.transcriptId)}>
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );
}

export default TranscriptTable;
