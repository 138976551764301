import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './pages/Admin/Admin.page';
import HomePage from './pages/Home/Home.page';
import Signin from './pages/User/signin';
import Signup from './pages/User/signup';
import PrivateRoute from './helpers/protectedRoutes';
import UploadPage from './pages/Upload/Upload.page';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/admin" element={<PrivateRoute> <AdminPage /> </PrivateRoute>} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/admin/upload" element={<PrivateRoute> <UploadPage /> </PrivateRoute>} />
    </Routes>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
