import React, { ChangeEvent, useEffect, useState } from 'react';
import './Home.page.css';
import '../../ui/Shared.css';
import { IoMdSend } from 'react-icons/io';
import { IoLogoElectron } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import InputField from '../../ui/components/InputField';
import DropDownMenu from '../../ui/components/DropDownMenu';
import NumberInputField from '../../ui/components/NumberInputField';
import SourcesTextField from '../../ui/components/SourcesTextField';
import LargeInputField from '../../ui/components/LargeInputField';

const isMock = process.env.REACT_APP_MOCK === 'true';

interface Source {
  videoTitle: string;
  videoURL: string;
  relevancy: string;
  associatedStudies: {
    studyTitle: string;
    studyURL: string;
    studyAbstract: string;
    studyKeywords: string;
  }[];
}

interface ChatbotResponse {
  response: string;
  sources: Source[];
  responseChatId: number;
}

// TODO should this be the HomePage? Should this instead be called ChatPage or something else?

function HomePage() {
  const [query, setQuery] = useState<string>('');
  const [hide, setHide] = useState(true);
  const [educationLevel, setEducationLevel] = useState<string>('A high school student');
  const levels = ['Short', 'Medium', 'Long'];
  const [detailLevel, setDetailLevel] = useState<string>(levels[0]);
  const [threshold, setThreshold] = useState<number>(40);
  const [botResponse, setBotResponse] = useState<string>('');
  const [sources, setSources] = useState<Source[]>([]);
  const [chatId, setChatId] = useState<number>(-1); // -1 to indicate no chatId is assigned yet, perhapse null would be better?
  const [userId, setUserId] = useState<number>(-1); // this would be changed on login, currently no login so it has a filled in value
  const [ws, setWs] = useState<WebSocket | null>(null);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleEducationLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEducationLevel(event.target.value);
  };

  const handleThresholdChange = (value: number) => {
    setThreshold(value);
  };

  const startWebSocket = (callback?: () => void) => {
    // Close the previous WebSocket if it's already open
    const mockEndpoint = isMock ? '/mock' : '';
    if (ws) {
      ws.close();
      setWs(null);
    }

    // Initialize the WebSocket connection
    let socket: WebSocket;
    if (process.env.REACT_APP_ENV === 'local') {
      socket = new WebSocket(`ws://localhost:3000/chatbot${mockEndpoint}`);
    } else {
      socket = new WebSocket(
        `${window.location.origin.replace(/^http/, 'ws')}/chatbot${mockEndpoint}`,
      );
    }

    // Set the WebSocket on open connection and call the callback if provided
    socket.onopen = () => {
      setWs(socket);
      if (callback) {
        callback(); // Send query only when WebSocket is open
      }
    };

    // Handle incoming messages
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'chat-id':
          setChatId(data.id!); // Assume chatId is number
          break;
        case 'ai-response-chunk':
          setBotResponse((prev) => prev + data.text!);
          break;
        case 'message-end':
          // use this if you need a flag for the message is finished
          break;
        case 'no-sources':
          setBotResponse(data.text!);
          break;
        case 'ai-sources':
          if (data.sources) {
            try {
              const newSources = data.sources as Source[];
              setSources(newSources);
            } catch (error) {
              console.log(`There was an error when getting sources`);
              console.log(error);
            }
          }
          break;
        default:
          console.error('Unknown message type:', data.type);
      }
    };

    socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  };

  const sendQuery = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const payload = {
        query: query,
        educationLevel: educationLevel,
        detailLevel: detailLevel,
        threshold: threshold,
        userId: userId,
        chatId: chatId === -1 ? null : chatId,
      };

      ws.send(JSON.stringify(payload));
      setBotResponse('');
      setQuery('');
    }
  };

  const handleSubmit = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      sendQuery();
    } else {
      startWebSocket(sendQuery); // Ensure WebSocket is open before sending the query
    }
  };

  // Set up the WebSocket on component mount
  useEffect(() => {
    startWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <div className="homePageContainer">
      <header className="homePageheader">
        <div className="headerWrapper">
          <div className="logoName">
            <IoLogoElectron className="logo" />
            <h3>Video Search AI</h3>
          </div>
          <div className="gearIconDiv">
            <IoSettingsOutline
              className="gearIcon"
              onClick={() => {
                handleHide();
              }}
            />
            <div className={`homePageSetting ${hide ? 'hidden' : ''}`}>
              <div>
                <p>Education Level:</p>
                <InputField
                  value={educationLevel}
                  label={'Education Level'}
                  onValueChange={handleEducationLevelChange}
                ></InputField>
              </div>
              <div>
                <p> Detail Amount: </p>
                <DropDownMenu
                  choices={levels}
                  onSelect={(index) => setDetailLevel(index)}
                ></DropDownMenu>
              </div>
              <div>
                <p>Relevancy Threshold:</p>
                <NumberInputField
                  value={threshold}
                  label={'Threshold'}
                  onValueChange={handleThresholdChange}
                ></NumberInputField>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="homePageMainWrapper">
        <div className={botResponse !== '' ? 'homePageReferences' : ''}>
          <SourcesTextField sources={sources} />
        </div>
        <div className={botResponse !== '' ? 'homePageChatArea' : 'defaultWidth'}>
          <div className="homePageResponse">
            {botResponse !== '' ? (
              <div className="responseAreaWrapper">
                <IoLogoElectron className="defaultScreenIcon" />
                <div className="homePageBotResponse">{botResponse}</div>
              </div>
            ) : (
              <div className="defaultScreen">
                <IoLogoElectron className="defaultScreenIcon" />
                <h4 className="defaultScreenName">Video Search AI</h4>
              </div>
            )}
          </div>
          <div className="homePageQuery">
            <LargeInputField
              value={query}
              label={'Query'}
              onValueChange={handleQueryChange}
            ></LargeInputField>
            <IoMdSend onClick={handleSubmit} className="homePageConfirmButton" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
